import { messaging } from "firebaseConfig/firebase";
import { onMessage } from "firebase/messaging";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setMessage } from "store/fcm/fcmSlice";
import { useAppSelector } from "store/hooks";
import { handleChatNotificationCount, handleNotificationCount } from "utils/noitificationHandler";
import { RootState } from "@store/index";

const FCMStore = () => {
  const dispatch = useDispatch();
  const userId = useAppSelector((state) => state.auth.user?._id);
  const currentMatch = useAppSelector(
    (state: RootState) => state.currentMatch
  ).currentMatch;

  const freePlay = currentMatch?.cubeWager === 0;

  onMessage(messaging, (payload) => {
    dispatch(setMessage(payload));

    if (userId === payload?.data?.senderId) {
      return;
    }
    switch (payload?.data?.type) {
      case "DO NOT SHOW":
        break;

      case "PRIVATE_MESSAGE":
      case "COMMUNITY_MESSAGE":
        handleChatNotificationCount(1);
        return toast(`You have a new message from ${payload?.data?.sender}`);

      case "MATCH_MESSAGE":
        handleNotificationCount(1);
        return;

      case "TOURNAMENT_INVITATION":
      case "MATCH_CHALLENGE":
      case "CHALLENGE_INVITE":
        toast.success("You have received a new Invitation/Challenge");
        handleNotificationCount(1);
        return;

      case "READY_FOR_TOURNAMENT":
        toast.info("Your scheduled tournament will start in 10 minutes");
        return;

      default:
        if (!freePlay) toast(payload?.data?.message);
        handleNotificationCount(1);
    }
  });
  return <></>;
};

export default FCMStore;
