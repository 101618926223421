import "./App.css";
import { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { requestPermission } from "utils/noitificationHandler";
import { ToastContainer, toast } from "react-toastify";
import { getTokenFromFirebase } from "firebaseConfig/firebase";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import store from "./store";

import SignUpScreen from "screens/SignUp/signUp.screen";
import LoginScreen from "screens/Login/login.screen";
import HomeScreen from "screens/Home/Home.screen";

import SelectPlatformScreen from "screens/Duelpath/SelectPlatform/selectPlatform.screen";
import SelectGameTypeScreen from "screens/Duelpath/SelectGameType/selectGameType.screen";
import ScheduleMatchScreen from "screens/Duelpath/ScheduleMatch/scheduleMatch.screen";
import SelectDuelPathScreen from "screens/Duelpath/SelectDuelPath/selectDuelPath.screen";
import HowManyRoundsScreen from "screens/Duelpath/HowmanyRounds/howManyRounds.screen";
import SelectGameScreen from "screens/Duelpath/SelectGame/SelectGame.screen";
import PresetScreen from "screens/Duelpath/Presets/preset.screen";
import HowManyCubesScreen from "screens/Duelpath/HowmanyCubes/howmanyCubes.screen";
import ChallengeOthersScreen from "screens/Duelpath/ChallengeOthers/challengeOthers.screen";
import DuelOverviewScreen from "screens/Duelpath/DuelOverview/duelOverview.screen";
import LobbyScreen from "screens/Duelpath/LobbyScreen/Lobby.screen";
import InviteOthersScreen from "screens/Duelpath/InviteOthers/inviteOthers.screen";
import DuelEngagedScreen from "screens/Duelpath/DuelEngaged/duelEngaged.screen";
import MoreEvents from "screens/Home/MoreEvents/moreEvents";
import ConfirmVoteScreen from "screens/Duelpath/ConfirmVote/confirmVote.screen";
import DuelWonScreen from "screens/Duelpath/DuelWon/duelWon.screen";
import DisputeScreen from "screens/Duelpath/Dispute/dispute.screen";
import ProfileScreen from "screens/Profile/profile.screen";
import PaymentStatusScreen from "screens/Duelpath/PaymentStatus/paymentStatus.screen";
import DuelLostScreen from "screens/Duelpath/DuelLost/duelLost.screen";
import TimeSelectScreen from "screens/Duelpath/TimeSelect/timeSelect.screen";
import VerifyOtpScreen from "screens/VerifyOtp/verifyOtp.screen";
import MatchmakingScreen from "screens/Duelpath/Matchmakings/matchmaking.screen";
import PaypalScreen from "screens/Duelpath/ManageAccounts/paypal.screen";
import UserDisputesScreen from "screens/Duelpath/Dispute/userDisputes.screen";
import EditProfileScreen from "screens/Profile/editProfile.screen";
import ReferralsScreen from "screens/Blog/Referrals/Referrals.screen";
import FCMStore from "components/FCMStore";
import AboutStakingMainScreen from "screens/Staking/AboutStakings/aboutStakingMain.screen";
import StakingIntroScreen from "screens/Staking/StakingIntro/stakingIntro.screen";
import StakingAmountScreen from "screens/Staking/stakingAmount.screen";
import SupportScreen from "screens/Support/Support.screen";
import DraggableComponent from "components/InMatchView";
import WalletScreen from "screens/Wallet/wallet.screen";
import SendMoneyScreen from "screens/Wallet/SendMoney/sendMoney.screen";
import ConfirmAmout from "screens/Wallet/ConfirmAmount/confirmAmount.screen";
import TransactionSummeryScreen from "screens/Wallet/TransactionSummery/transactionSummery.screen";
import TransactionCompleteScreen from "screens/Wallet/TransactionComplete/transactionComplete.screen";
import TransactionReceiptScreen from "screens/Wallet/Receipt/receipt.screen";
import CommunicationScreen from "screens/Communities/communication.screen";
import WithdrawingIntroScreen from "screens/Wallet/WithdrawIntro/withdrawIntro.screen";
import UploadIdScreen from "screens/Wallet/Verification/UploadId/uploadId.screen";
import UploadSelfieScreen from "screens/Wallet/Verification/UploadSelfie/uploadSelfie.screen";
import VerificationProcessingScreen from "screens/Wallet/Processing/processing.screen";
import WithdrawFundsScreen from "screens/Wallet/WithdrawFunds/withdrawFunds.screen";
import TransactionHistoryScreen from "screens/Wallet/TransactionHistory/transactionHistory.screen";
import CommunityRulesScreen from "screens/Communities/CommunityRules/communityRules.screen";
import AddTitleScreen from "screens/Communities/AddTitle/addTitleScreen.screen";
import SelectCommunityImageScreen from "screens/Communities/SelectCommunityImage/selectCommunityImage.screen";
import CreateCommunityScreen from "screens/Communities/CreateCommunity/createCommunity.screen";
import AddDescriptionScreen from "screens/Communities/AddDescription/addDescription.screen";
import InvitePeopleToCommunityScreen from "screens/Communities/InvitePeopleToCommunity/invitePeopleToCommunity.screen";
import TournamentTypeScreen from "screens/Tournament/TournamentType/tournamentType.screen";
import ForgotPasswordScreen from "screens/ForgotPassword/forgotPassword.screen";
import CreateNewPasswordScreen from "screens/ForgotPassword/createNewPassword.screen";
import LiveBracketsScreen from "screens/Tournament/LiveBrackets/liveBrackets.screen";
import CreateMatchScreen from "screens/Tournament/CreateMatches/createMatches.screen";
import TournamentSummaryScreen from "screens/Tournament/Summary/tournamentSummary.screen";
import SettingsScreen from "screens/Settings/settings.screen";
import LeaderboardScreen from "screens/Leaderboard/leaderboard.screen";
import ManageAccountsScreen from "screens/Settings/ManageAccounts/manageAccounts";
import ReferFriendScreen from "screens/Settings/ReferFriend/referFriend";
import PastInvitesScreen from "screens/Settings/ReferFriend/pastInvites";
import ContactUsScreen from "screens/Settings/ContactUs/contactUs";
import RequestSupportScreen from "screens/Settings/RequestSupport/requestSupport";
import DeleteAccountScreen from "screens/Settings/DeleteAccount/deleteAccount";
import StreamingPlatformScreen from "screens/Streaming/GameStreaming/StreamingPlatform/streamingPlatform.screen";
import StreamingToolScreen from "screens/Streaming/GameStreaming/StreamingTool/streamingTool.screen";
import StreamingMorePlacesScreen from "screens/Streaming/GameStreaming/StreamingMorePlaces/streamingMorePlaces.screen";
import PrivateRoute from "privateRoute";
import StreamHubScreen from "screens/Streaming/MainSteaming/StreamHub/streamHub.screen";
import LiveSetupScreen from "screens/Streaming/MainSteaming/liveSetup/liveSetup.screen";
import StreamingMethodScreen from "screens/Streaming/MainSteaming/StreamingMethod/streamingMethod.screen";
import MorePlacesScreen from "screens/Streaming/MainSteaming/MorePlaces/morePlaces.screen";
import PreviewStreamScreen from "screens/Streaming/MainSteaming/PreviewStream/previewStream.screen";
import PreviewLiveStreamScreen from "screens/Streaming/MainSteaming/PreviewLiveStream/previewLiveStream.screen";
import GameHubScreen from "screens/GameHub/gameHub.screen";
import WageringOptionsScreen from "screens/WageringOptions/WageringOptions.screen";
import TopUpFundsScreen from "screens/Wallet/TopUpFunds/topUpFunds.screen";
import GameStartingScreen from "screens/GameHub/gameStarting.screen";
import GameRecordsScreen from "screens/GameHub/GameRecords/gameRecords.screen";
import RecordScreen from "screens/GameHub/GameRecords/record.screen";
import GameLeaderBoardMain from "screens/GameHub/GameLeaderBoard/gameLeaderBoardMain.screen";
import GameLeaderBoard from "screens/GameHub/GameLeaderBoard/gameLeaderBoard.screen";
import MainGameHubScreen from "screens/GameHub/mainGameHub.screen";
import CubesGameMatchScreen from "screens/GameHub/Challenge/cubesGameMatchScreen.screen";
import ChallengeForGamesScreen from "screens/GameHub/Challenge/challengeForGamesScreen";
import GameOverviewScreen from "screens/GameHub/Challenge/gameOverview.screen";
import SelectMiniGameScreen from "screens/GameHub/Challenge/selectMiniGame.screen";
import GameLobbyScreen from "screens/GameHub/Challenge/gameLobby.screen";
import ResultsScreen from "screens/GameHub/Challenge/results.screen";
import GamePaymentStatusScreen from "screens/GameHub/Challenge/gamePaymentStatus.screen";
import GameWaitingScreen from "screens/GameHub/Challenge/gameWaiting.screen";

function App() {
  useEffect(() => {
    if (!window.Notification || !navigator.serviceWorker) {
      requestPermission();
      getTokenFromFirebase();
    }
  }, []);

  return (
    <Provider store={store}>
      <FCMStore />
      <div className="App">
        <ToastContainer
          theme="dark"
          progressStyle={{ backgroundColor: "#EEB033" }}
          stacked
          pauseOnHover={false}
          closeOnClick
          draggable
        />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<HomeScreen />} />
            <Route
              path="/wagering"
              element={
                <PrivateRoute>
                  <WageringOptionsScreen />
                </PrivateRoute>
              }
            />
            <Route path="/duels">
              <Route index element={<SelectDuelPathScreen />} />
              <Route
                path="schedule-match"
                element={
                  <PrivateRoute>
                    <ScheduleMatchScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="select-game"
                element={
                  <PrivateRoute>
                    <SelectGameScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="select-platform"
                element={
                  <PrivateRoute>
                    <SelectPlatformScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="select-game-type"
                element={
                  <PrivateRoute>
                    <SelectGameTypeScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="select-game-type/:from"
                element={
                  <PrivateRoute>
                    <SelectGameTypeScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="time-select"
                element={
                  <PrivateRoute>
                    <TimeSelectScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="presets"
                element={
                  <PrivateRoute>
                    <PresetScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="how-many-rounds"
                element={
                  <PrivateRoute>
                    <HowManyRoundsScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="how-many-cubes"
                element={
                  <PrivateRoute>
                    <HowManyCubesScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="how-many-cubes/:from"
                element={
                  <PrivateRoute>
                    <HowManyCubesScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="how-many-rounds/:from"
                element={
                  <PrivateRoute>
                    <HowManyRoundsScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="challenge-others/:matchId?"
                element={
                  <PrivateRoute>
                    <ChallengeOthersScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="invite-others/:matchId?"
                element={
                  <PrivateRoute>
                    <InviteOthersScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="duel-overview"
                element={
                  <PrivateRoute>
                    <DuelOverviewScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="duel-overview/:paramMatchId"
                element={
                  <PrivateRoute>
                    <DuelOverviewScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="lobby/:matchId?/:stream?"
                element={
                  <PrivateRoute>
                    <LobbyScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="lobby"
                element={
                  <PrivateRoute>
                    <LobbyScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="engaged/:matchId"
                element={
                  <PrivateRoute>
                    <DuelEngagedScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="vote/:matchId/:from"
                element={
                  <PrivateRoute>
                    <ConfirmVoteScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="won/:matchId"
                element={
                  <PrivateRoute>
                    <DuelWonScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="lost/:matchId"
                element={
                  <PrivateRoute>
                    <DuelLostScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="dispute/:matchId"
                element={
                  <PrivateRoute>
                    <DisputeScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="payment-status/:paid?/:matchId"
                element={
                  <PrivateRoute>
                    <PaymentStatusScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="matchMaking"
                element={
                  <PrivateRoute>
                    <MatchmakingScreen />
                  </PrivateRoute>
                }
              />
            </Route>
            <Route
              path="/profile/:userId?"
              element={
                <PrivateRoute>
                  <ProfileScreen />
                </PrivateRoute>
              }
            />
            <Route
              path="/leaderboard"
              element={
                <PrivateRoute>
                  <LeaderboardScreen />
                </PrivateRoute>
              }
            />
            <Route
              path="/edit-profile/:from?"
              element={
                <PrivateRoute>
                  <EditProfileScreen />
                </PrivateRoute>
              }
            />
            <Route
              path="/chat"
              element={
                <PrivateRoute>
                  <HomeScreen />
                </PrivateRoute>
              }
            />
            <Route
              path="/options"
              element={
                <PrivateRoute>
                  <HomeScreen />
                </PrivateRoute>
              }
            />
            <Route
              path="/notifications"
              element={
                <PrivateRoute>
                  <HomeScreen />
                </PrivateRoute>
              }
            />
            <Route path="/login" element={<LoginScreen />} />
            <Route
              path="/forgot-password"
              element={<ForgotPasswordScreen />}
            />
            <Route
              path="/create-new-password"
              element={<CreateNewPasswordScreen />}
            />
            <Route path="/sign-up" element={<SignUpScreen />} />
            <Route path="/verify-otp/:type" element={<VerifyOtpScreen />} />
            <Route
              path="/more-events/:eventType"
              element={
                <PrivateRoute>
                  <MoreEvents />
                </PrivateRoute>
              }
            />
            <Route path="/manage-accounts">
              <Route
                path="paypal"
                element={
                  <PrivateRoute>
                    <PaypalScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="paypal/:from"
                element={
                  <PrivateRoute>
                    <PaypalScreen />
                  </PrivateRoute>
                }
              />
            </Route>
            <Route
              path="/disputes/:from?"
              element={
                <PrivateRoute>
                  <UserDisputesScreen />
                </PrivateRoute>
              }
            />
            <Route
              path="/blog"
              element={
                <PrivateRoute>
                  <ReferralsScreen />
                </PrivateRoute>
              }
            >
              <Route
                path="referrals"
                element={
                  <PrivateRoute>
                    <ReferralsScreen />
                  </PrivateRoute>
                }
              />
            </Route>
            <Route path="/stakes">
              <Route
                path="main"
                element={
                  <PrivateRoute>
                    <AboutStakingMainScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="intro"
                element={
                  <PrivateRoute>
                    <StakingIntroScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="stake-amount"
                element={
                  <PrivateRoute>
                    <StakingAmountScreen />
                  </PrivateRoute>
                }
              />
            </Route>
            <Route
              path="/support"
              element={
                <SupportScreen />
              }
            ></Route>
            <Route path="/wallet">
              <Route
                path="main/:payId?"
                element={
                  <PrivateRoute>
                    <WalletScreen />
                  </PrivateRoute>
                }
              ></Route>
              <Route
                path="send-money/:from"
                element={
                  <PrivateRoute>
                    <SendMoneyScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="confirm-amount/:from"
                element={
                  <PrivateRoute>
                    <ConfirmAmout />
                  </PrivateRoute>
                }
              />
              <Route
                path="transaction-summery/:from"
                element={
                  <PrivateRoute>
                    <TransactionSummeryScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="transaction-complete/:from"
                element={
                  <PrivateRoute>
                    <TransactionCompleteScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="transaction-receipt/:from"
                element={
                  <PrivateRoute>
                    <TransactionReceiptScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="withdraw-intro"
                element={
                  <PrivateRoute>
                    <WithdrawingIntroScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="upload-id/:authId?"
                element={
                  <PrivateRoute>
                    <UploadIdScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="upload-selfie/:mobile?"
                element={
                  <PrivateRoute>
                    <UploadSelfieScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="verification-processing/:mobile?"
                element={
                  <PrivateRoute>
                    <VerificationProcessingScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="withdraw-funds"
                element={
                  <PrivateRoute>
                    <WithdrawFundsScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="top-up-funds/:userId?"
                element={
                  <PrivateRoute>
                    <TopUpFundsScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="transaction-history"
                element={
                  <PrivateRoute>
                    <TransactionHistoryScreen />
                  </PrivateRoute>
                }
              />
            </Route>
            <Route path="/communication">
              <Route
                path="main/:communityId?"
                element={
                  <PrivateRoute>
                    <CommunicationScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="community-rules"
                element={
                  <PrivateRoute>
                    <CommunityRulesScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="select-community-image"
                element={
                  <PrivateRoute>
                    <SelectCommunityImageScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="create-community"
                element={
                  <PrivateRoute>
                    <CreateCommunityScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="add-title"
                element={
                  <PrivateRoute>
                    <AddTitleScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="add-description"
                element={
                  <PrivateRoute>
                    <AddDescriptionScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="invite-to-community/:from?"
                element={
                  <PrivateRoute>
                    <InvitePeopleToCommunityScreen />
                  </PrivateRoute>
                }
              />
            </Route>
            <Route path="/tournament">
              <Route
                path="type"
                element={
                  <PrivateRoute>
                    <TournamentTypeScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="type/:from"
                element={
                  <PrivateRoute>
                    <TournamentTypeScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="live-bracket/:nextRound?"
                element={
                  <PrivateRoute>
                    <LiveBracketsScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="create-match"
                element={
                  <PrivateRoute>
                    <CreateMatchScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="time-select/:tournamentID"
                element={
                  <PrivateRoute>
                    <TimeSelectScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="summary/:tournamentID"
                element={
                  <PrivateRoute>
                    <TournamentSummaryScreen />
                  </PrivateRoute>
                }
              />
            </Route>
            <Route path="/settings">
              <Route
                index
                element={
                  <PrivateRoute>
                    <SettingsScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="manage-accounts/:matchId?"
                element={
                  <PrivateRoute>
                    <ManageAccountsScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="refer-friend"
                element={
                  <PrivateRoute>
                    <ReferFriendScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="past-invites"
                element={
                  <PrivateRoute>
                    <PastInvitesScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="contact-us"
                element={
                  <PrivateRoute>
                    <ContactUsScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="support"
                element={
                  <PrivateRoute>
                    <RequestSupportScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="delete"
                element={
                  <PrivateRoute>
                    <DeleteAccountScreen />
                  </PrivateRoute>
                }
              />
            </Route>
            <Route path="/streaming/:matchId?">
              <Route
                path="platform"
                element={
                  <PrivateRoute>
                    <StreamingPlatformScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="tool"
                element={
                  <PrivateRoute>
                    <StreamingToolScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="more-places"
                element={
                  <PrivateRoute>
                    <StreamingMorePlacesScreen />
                  </PrivateRoute>
                }
              />
            </Route>
            <Route path="/stream">
              <Route
                path="hub"
                element={
                  <PrivateRoute>
                    <StreamHubScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="live-setup/:from?"
                element={
                  <PrivateRoute>
                    <LiveSetupScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="streaming-method"
                element={
                  <PrivateRoute>
                    <StreamingMethodScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="streaming-more-places"
                element={
                  <PrivateRoute>
                    <MorePlacesScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="preview-stream/:from?"
                element={
                  <PrivateRoute>
                    <PreviewStreamScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="preview-live-stream/:from?/:streamId?"
                element={
                  <PrivateRoute>
                    <PreviewLiveStreamScreen />
                  </PrivateRoute>
                }
              />
            </Route>

            <Route path="/gaming">
              <Route index element={<MainGameHubScreen />} />
              <Route
                path="games/:path?"
                element={
                  <PrivateRoute>
                    <GameHubScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="select-game/:path?"
                element={
                  <PrivateRoute>
                    <SelectMiniGameScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="cubes/:from?"
                element={
                  <PrivateRoute>
                    <CubesGameMatchScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="overview/:matchId?"
                element={
                  <PrivateRoute>
                    <GameOverviewScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="challenge/:matchId?"
                element={
                  <PrivateRoute>
                    <ChallengeForGamesScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="game-lobby/:matchId?"
                element={
                  <PrivateRoute>
                    <GameLobbyScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="game-starting/:challengeId?"
                element={
                  <PrivateRoute>
                    <GameStartingScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="game-waiting/:challengeId?"
                element={
                  <PrivateRoute>
                    <GameWaitingScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="game-results/:challengeId?"
                element={
                  <PrivateRoute>
                    <ResultsScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="game-payment-status/:paid?/:challengeId"
                element={
                  <PrivateRoute>
                    <GamePaymentStatusScreen />
                  </PrivateRoute>
                }
              />
              <Route path="game-records">
                <Route index element={<GameRecordsScreen />} />
                <Route
                  path="record/:game?"
                  element={
                    <PrivateRoute>
                      <RecordScreen />
                    </PrivateRoute>
                  }
                />
              </Route>
              <Route path="game-leaderBoard-main">
                <Route index element={<GameLeaderBoardMain />} />
                <Route
                  path="game-leaderBoard/:game?"
                  element={
                    <PrivateRoute>
                      <GameLeaderBoard />
                    </PrivateRoute>
                  }
                />
              </Route>
            </Route>
          </Routes>
          <DraggableComponent />
        </BrowserRouter>
      </div>
    </Provider>
  );
}

export default App;
