import { createSlice } from "@reduxjs/toolkit";
import {
  capturePayment,
  checkIfBalanceIsEnough,
  getBalance,
  getQuickAccess,
  getReceivers,
  getTransactionHistory,
  requestMoney,
  requestPayout,
  sendMoney,
} from "./action";
import { signOut } from "store/auth/actions";
import { digitalPayment } from "store/payments/action";

interface Receiver {
  _id: string;
  username: string;
  profilePicture: string;
  phoneNumber: string;
  accountBalance: string;
}

export interface Wallet {
  dcBalance: number;
  balance: number;
  paypalMail?: String;
  transactions: any[];
  invoice?: {
    sender: String;
    receiver: string;
    amount: string;
    transactionId: String;
    date: string | Date;
    status: string;
    transferType: string;
  };
  loader: boolean;
  error: {} | null;
  quickAccessUsers: any[];
  allReceivers: any[];
  receiver: Receiver | null;
  sendAmount: String;
  page: number;
  uploadingDocuments: any[];
  transactionHistory: any[];
  transactionHistoryPage: number;
  totalTransactionPages?: number;
  donation?: number;
}

const initialState: Wallet = {
  loader: false,
  transactions: [],
  balance: 0,
  dcBalance: 0,
  error: null,
  quickAccessUsers: [],
  allReceivers: [],
  receiver: null,
  sendAmount: "",
  page: 1,
  uploadingDocuments: [],
  transactionHistory: [],
  transactionHistoryPage: 1,
  donation: 0,
};

const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    setTransactionHistoryPage: (state, action) => {
      state.transactionHistoryPage = action.payload;
    },
    updateBalance: (state, action) => {
      state.balance = action.payload;
    },
    setReceiver: (state, action) => {
      state.receiver = action.payload;
    },
    setAmount: (state, action) => {
      state.sendAmount = action.payload;
    },
    removeReceiver: (state) => {
      state.receiver = null;
    },
    setUploadingDocuments: (state, action) => {
      state.uploadingDocuments = [...state.uploadingDocuments, action.payload];
    },
    setDonation: (state, action) => {
      state.donation = action.payload;
    },
    cleanDocuments: (state) => {
      state.uploadingDocuments = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBalance.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getBalance.fulfilled, (state, action) => {
      state.balance = action.payload.accountBalance;
      state.dcBalance = action.payload.dcBalance;
      state.paypalMail = action.payload.paypalMail;
      state.loader = false;
    });
    builder.addCase(getBalance.rejected, (state) => {
      state.error = true;
      state.loader = false;
    });
    builder.addCase(checkIfBalanceIsEnough.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(checkIfBalanceIsEnough.fulfilled, (state) => {
      state.loader = false;
    });
    builder.addCase(checkIfBalanceIsEnough.rejected, (state) => {
      state.error = true;
      state.loader = false;
    });
    builder.addCase(capturePayment.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(capturePayment.fulfilled, (state) => {
      state.loader = false;
    });
    builder.addCase(capturePayment.rejected, (state) => {
      state.error = true;
      state.loader = false;
    });
    builder.addCase(getQuickAccess.fulfilled, (state, action) => {
      state.quickAccessUsers = action.payload?.userList;
      state.loader = false;
    });
    builder.addCase(getReceivers.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getReceivers.fulfilled, (state, action) => {
      state.allReceivers = action.payload?.userList;
      state.loader = false;
    });
    builder.addCase(getReceivers.rejected, (state) => {
      state.loader = false;
    });
    builder.addCase(sendMoney.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(sendMoney.rejected, (state) => {
      state.loader = false;
    });
    builder.addCase(sendMoney.fulfilled, (state, action) => {
      state.loader = false;
      state.balance = action.payload.accountBalance;
      state.dcBalance = action.payload.dcBalance;
      state.invoice = {
        sender: action.payload.sender,
        receiver: action.payload.receiver,
        amount: action.payload.amountTransferred,
        transactionId: action.payload.transactionId,
        date: action.payload.date ? action.payload.date : new Date(),
        status: action.payload.status ? action.payload.status : "SUCCESS",
        transferType: action.payload.transferType,
      };
    });
    builder.addCase(requestMoney.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(requestMoney.rejected, (state) => {
      state.loader = false;
    });
    builder.addCase(requestMoney.fulfilled, (state, action) => {
      state.loader = false;
      state.balance = action.payload.accountBalance;
      state.dcBalance = action.payload.dcBalance;
      state.invoice = {
        sender: action.payload.sender,
        receiver: action.payload.receiver,
        amount: action.payload.amountTransferred,
        transactionId: action.payload.transactionId,
        date: action.payload.date ? action.payload.date : new Date(),
        status: action.payload.status ? action.payload.status : "PENDING",
        transferType: action.payload.transferType,
      };
    });
    builder.addCase(requestPayout.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(requestPayout.rejected, (state) => {
      state.loader = false;
    });
    builder.addCase(requestPayout.fulfilled, (state, action) => {
      state.loader = false;
      state.balance = action.payload.accountBalance;
      state.dcBalance = action.payload.dcBalance;
      state.invoice = {
        sender: action.payload.sender,
        receiver: action.payload.receiver,
        amount: action.payload.amountTransferred,
        transactionId: action.payload.transactionId,
        date: action.payload.date ? action.payload.date : new Date(),
        status: action.payload.status ? action.payload.status : "PENDING",
        transferType: action.payload.transferType,
      };
    });
    builder.addCase(getTransactionHistory.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getTransactionHistory.rejected, (state) => {
      state.loader = false;
    });
    builder.addCase(getTransactionHistory.fulfilled, (state, action) => {
      state.transactionHistory = action.payload.transactionHistory;
      state.totalTransactionPages = action.payload.totalPages;
      state.loader = false;
    });
    builder.addCase(signOut.fulfilled, (state) => {
      return initialState;
    });
    builder.addCase(digitalPayment.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(digitalPayment.fulfilled, (state) => {
      state.loader = false;
    });
    builder.addCase(digitalPayment.rejected, (state) => {
      state.error = true;
      state.loader = false;
    });
  },
});

export default walletSlice.reducer;

export const {
  setTransactionHistoryPage,
  updateBalance,
  setReceiver,
  removeReceiver,
  setAmount,
  setUploadingDocuments,
  setDonation,
  cleanDocuments,
} = walletSlice.actions;
