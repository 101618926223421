import store from "store/index";
import "react-toastify/dist/ReactToastify.css";
import {
  setChatNotificationCount,
  setNotificationCount,
} from "store/notifications/notificationSlice";

export async function requestPermission() {
  const permission = await Notification.requestPermission();

  if (permission === "granted") {
    console.log("Notification permission granted.");
  } else if (permission === "denied") {
    alert("You denied for the notification");
  }
}
export const handleNotificationCount = (count?: number) => {
  if (count === -1) {
    localStorage.setItem("notificationCount", JSON.stringify(0));
    return store.dispatch(setNotificationCount(0));
  }

  const localCount = localStorage.getItem("notificationCount");

  if (!localCount || localCount === "null") {
    localStorage.setItem("notificationCount", JSON.stringify(count || 0));
    return store.dispatch(setNotificationCount(count || 0));
  }

  const newCount = JSON.parse(localCount) + (count || 0);
  localStorage.setItem("notificationCount", JSON.stringify(newCount));
  store.dispatch(setNotificationCount(newCount));
};

export const handleChatNotificationCount = (count?: number) => {
  if (count === -1) {
    localStorage.setItem("chatNotificationCount", JSON.stringify(0));
    return store.dispatch(setChatNotificationCount(0));
  }

  const localCount = localStorage.getItem("chatNotificationCount");

  if (!localCount || localCount === "null") {
    localStorage.setItem("chatNotificationCount", JSON.stringify(count || 0));
    return store.dispatch(setChatNotificationCount(count || 0));
  }

  const newCount = JSON.parse(localCount) + (count || 0);
  localStorage.setItem("chatNotificationCount", JSON.stringify(newCount));
  store.dispatch(setChatNotificationCount(newCount));
};

// onMessage(messaging, (payload) => {
//   console.log("Message received. ", payload);
//   toast(payload.notification?.body);
// });
