import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "store/hooks";
import { AppDispatch, RootState } from "store/index";
import { useDispatch } from "react-redux";
import { createMatch, joinLobby } from "store/matchMaking/actions";
import { getMatchById, handleNotifications } from "store/notifications/actions";
import { toast } from "react-toastify";
import { getTournamentById } from "store/liveBrackets/actions";
import { gameTypeValues, gameTypes } from "types/constants";
import { resetCurrentMatch } from "store/currentMatch/currentMatchSlice";
import { getLinkedAccounts } from "store/linkedAccounts/action";
import {
  resetLoading,
} from "store/notifications/notificationSlice";
import {
  clearDate,
  setConsole,
  setMatchCubes,
  setSelectedGame,
  setSelectedGameType,
  setSelectedRound,
  setSpectators,
} from "store/matchMaking/matchMakingSlice";
import {
  getCommunityFriends,
  updateActivitionID,
  updateEAID,
  updateEpicID,
  updateMLBID,
  updatePsID,
  updateStreetFighterID,
  updateXboxID,
} from "store/user/action";
import {
  createMainTournament,
  joinMainTournament,
} from "store/tournament/action";
import {
  setSelectedTournamentGame,
  setSelectedTournamentGameType,
  setSelectedTournamentRound,
  setTournamentConsole,
  setTournamentCubes,
  setTournamentId,
  setTournamentType,
} from "store/tournament/tournamentSlice";
import {
  ACTIVITION,
  EA,
  EPIC,
  MLB,
  PLAYSTATION,
  STREETFIGHTER,
  XBOX,
} from "constant/platForms";

import UsersModal from "components/usersModal";
import CloseIcon from "assets/close-button.svg";
import ButtonDc from "components/Button";
import PresetModal from "components/PresetModal";
import LoadingAnim from "components/Loader";
import PlatFormsModal from "components/PlatForms";
import ConsolModal from "components/Consoles";
import MatchRulesModal from "../MatchRules/matchRulesModal";

interface EditableRowProps {
  title: string;
  subtitle: string;
  onEdit: () => void;
  spectators?: boolean;
}

const EditableRow: React.FC<EditableRowProps> = ({
  title,
  subtitle,
  onEdit,
  spectators,
}) => {
  const currentMatch = useAppSelector((state: RootState) => state.currentMatch);
  const { selectedDuelPath } = useAppSelector((state) => state.matchMaking);
  const user = useAppSelector((state: RootState) => state.auth);

  const editBtnVisible =
    selectedDuelPath !== "JOIN_MATCH" &&
    selectedDuelPath !== "OPPONENT_JOIN_MATCH" &&
    selectedDuelPath !== "OPPONENT_JOIN";

  const editType = spectators ? true : editBtnVisible;

  return (
    <div className="flex flex-row bg-borderColor items-center justify-between gap-5  w-1/2 h-16 rounded-lg p-2  max-md:w-11/12">
      <div className="flex flex-col justify-between gap-1">
        <span className="text-white">{title}</span>
        <span className="text-placeholder text-xs">{subtitle}</span>
      </div>
      {editType && (
        <button
          className="flex w-[150px] h-[35px] rounded-lg p-1 items-center justify-center bg-primary hover:bg-primaryActive max-md:w-1/5"
          onClick={onEdit}
        >
          <span>Edit</span>
        </button>
      )}
    </div>
  );
};

const DuelOverviewScreen = () => {
  const navigate = useNavigate();
  const params = useParams();
  const notificationData = useAppSelector(
    (state) => state.notification.actionData
  );
  const urlMatchId = params?.paramMatchId;

  const paramMatchId = notificationData?.actionData;
  const [isPresetModalVisible, setIsPresetModalVisible] = useState(false);
  const [selectedSpectators, setSelectedSpectators] = useState<null | any>([]);
  const [userModal, setUserModal] = useState(false);
  const [showConsoles, setShowConsoles] = useState(false);
  const [rulesModalOpen, setRulesModalOpen] = useState(true);
  const matchMaking: any = useAppSelector(
    (state: RootState) => state.matchMaking
  );
  const currentMatch = useAppSelector((state: RootState) => state.currentMatch);
  const tournaments = useAppSelector((state: RootState) => state.tournament);
  const notifications = useAppSelector(
    (state: RootState) => state.notification
  );
  const { selectedDuelPath } = useAppSelector((state) => state.matchMaking);
  const [visible, setVisible] = useState(false);
  const [disable, setDisable] = useState(false);
  const [data, setData] = useState<any>([]);
  const screenStatus = selectedDuelPath;
  const isTournament =
    selectedDuelPath === "CREATE_TOURNAMENT" ||
    selectedDuelPath === "OPPONENT_JOIN_MATCH" ||
    selectedDuelPath === "OPPONENT_JOIN";
  const isTournamentOpponent =
    selectedDuelPath === "OPPONENT_JOIN_MATCH" ||
    selectedDuelPath === "OPPONENT_JOIN";
  const rounds = isTournament
    ? tournaments.selectedRound
    : matchMaking?.selectedRound;
  const gameType = isTournament
    ? tournaments.selectedGameType
    : matchMaking?.selectedGameType;
  const matchCubes = isTournament ? tournaments.cubes : matchMaking?.matchCubes ? matchMaking?.matchCubes : "0";
  const matchType = isTournament
    ? tournaments.tournamentType
    : matchMaking?.selectedDuelPath;
  const game = isTournament
    ? tournaments.selectedGame
    : matchMaking?.selectedGame;
  const gameBanner = isTournament
    ? tournaments.selectedGame?.image
    : matchMaking?.selectedGame?.image;
  const selectedConsole = isTournament
    ? tournaments.console
    : matchMaking.console;

  const linkedAccounts = useAppSelector(
    (state: RootState) => state.linkedAccounts
  );
  const privacy = matchMaking?.privacy;
  const user = useAppSelector((state: RootState) => state.user);
  const isPrivate = privacy === "Private";
  const dispatch: AppDispatch = useDispatch();

  const date =
    screenStatus === "JOIN_MATCH"
      ? new Date(
        currentMatch?.currentMatch?.scheduledTime
          ? currentMatch?.currentMatch?.scheduledTime
          : currentMatch?.currentMatch?.createdAt
      )
      : isTournament
        ? new Date(tournaments.tournamentDate)
        : matchMaking?.date;

  useEffect(() => {
    if (!isTournament) return;
    if (
      screenStatus === "OPPONENT_JOIN_MATCH" ||
      screenStatus === "OPPONENT_JOIN"
    ) {
      dispatch(
        getTournamentById({
          payload: urlMatchId,
          callback: (data) => {
            dispatch(setSelectedTournamentGame(data.game));
            dispatch(setTournamentCubes(data.cubeWager));
            dispatch(setTournamentType(data.gameType));
            setSelectedSpectators(data.designatedSpectators);
            dispatch(setSelectedTournamentRound({ name: data.rounds }));
            dispatch(setSelectedTournamentGameType({ text: data.gameType }));
          },
          errLog: () => {
            toast.error(
              "The tournament you are looking for does not exist or has been deleted. Sending you back to the menu..."
            );
            setTimeout(() => {
              navigate("/");
            }, 400);
          },
        })
      );
    }
  }, []);

  useEffect(() => {
    dispatch(getLinkedAccounts());
    dispatch(getCommunityFriends(1));
  }, []);

  const handleClose = () => {
    dispatch(clearDate());
    navigate("/");
  };

  const joinTournament = () => {
    dispatch(resetCurrentMatch());
    dispatch(
      joinMainTournament({
        payload: urlMatchId,
        callback: () => {
          dispatch(setTournamentId(urlMatchId));
          return navigate(`/tournament/live-bracket/${urlMatchId}`);
        },
        err: () => {
          toast.error("Match not found.");
          setTimeout(() => {
            navigate("/");
          }, 400);
        },
      })
    );
  };


  const handleInvitationAccept = (item?: any) => {
    const matchId = paramMatchId?.split(",")[0];
    if (!matchMaking.console) return;
    dispatch(resetCurrentMatch());
    dispatch(
      handleNotifications({
        payload: {
          consoleType: selectedConsole.consoleType || item.consoleType,
          actionData: notificationData.actionData
            ? notificationData.actionData
            : urlMatchId,
          actionType: notificationData.actionType
            ? notificationData.actionType
            : "JOIN_TOURNAMENT",
          actionState: true,
        }, callback: (data) => {

          if (screenStatus === "OPPONENT_JOIN_MATCH") {
            dispatch(setTournamentId(urlMatchId));
            return navigate(`/tournament/live-bracket/${urlMatchId}`);
          } else {
            setTimeout(() => {
              if (!linkedAccounts.accounts.paypal)
                return navigate(`/manage-accounts/paypal/${paramMatchId}`);
              navigate(`/duels/lobby/${matchId}`);
            }, 500);
          }
        }
      })
    )
  };

  const onPress = (id: any) => {
    if (!id) return toast.error("Please enter valid details");
    switch (data) {
      case ACTIVITION:
        return dispatch(
          updateActivitionID({
            payload: { activisionId: id },
            callback: () => {
              setVisible(false);
              handleConnected();
            },
          })
        );
      case EA:
        return dispatch(
          updateEAID({
            payload: { eaId: id },
            callback: () => {
              setVisible(false);
              handleConnected();
            },
          })
        );
      case XBOX:
        return dispatch(
          updateXboxID({
            payload: { gammerTag: id },
            callback: () => {
              setVisible(false);
              handleConnected();
            },
          })
        );
      case PLAYSTATION:
        return dispatch(
          updatePsID({
            payload: { psnGamerTag: id },
            callback: () => {
              setVisible(false);
              handleConnected();
            },
          })
        );
      case EPIC:
        return dispatch(
          updateEpicID({
            payload: { epicId: id },
            callback: () => {
              setVisible(false);
              handleConnected();
            },
          })
        );
      case STREETFIGHTER:
        return dispatch(
          updateStreetFighterID({
            payload: { streetFighterId: id },
            callback: () => {
              setVisible(false);
              handleConnected();
            },
          })
        );
      case MLB:
        return dispatch(
          updateMLBID({
            payload: { mlbId: id },
            callback: () => {
              setVisible(false);
              dispatch(getLinkedAccounts());
            },
          })
        );
    }
  };

  const checkPlatformConnection = (item?: any) => {
    switch (
    selectedConsole?.provider ? selectedConsole.provider : item?.provider
    ) {
      case "epic":
        if (linkedAccounts.accounts.epic) return handleConnected(item);
        setVisible(true);
        setData(EPIC);
        break;
      case "ea":
        if (linkedAccounts.accounts.ea) return handleConnected(item);
        setVisible(true);
        setData(EA);
        break;
      case "psn":
        if (linkedAccounts.accounts.psn) return handleConnected(item);
        setVisible(true);
        setData(PLAYSTATION);
        break;
      case "activision":
        if (linkedAccounts.accounts.activisionId) return handleConnected(item);
        setVisible(true);
        setData(ACTIVITION);
        break;
      case "xbox":
        if (linkedAccounts.accounts.xbox) return handleConnected(item);
        setVisible(true);
        setData(XBOX);
        break;
      case "mlb":
        if (linkedAccounts.accounts.mlbId) return handleConnected(item);
        setVisible(true);
        setData(MLB);
        break;
      case "street_fighter":
        if (linkedAccounts.accounts.streetFighter) return handleConnected(item);
        setData(STREETFIGHTER);
        break;
    }
  };

  const handleConnected = (item?: any) => {
    if (screenStatus === "OPPONENT_JOIN_MATCH") return handleInvitationAccept(item);
    if (screenStatus === "OPPONENT_JOIN") return joinTournament();

    if (notificationData.actionData) return handleInvitationAccept(item);

    if (
      screenStatus === "JOIN_PRESET" ||
      screenStatus === "CREATE_MATCHMAKING"
    ) {
      return handlePresetModalNo();
    }
    if (screenStatus !== "JOIN_MATCH") {
      return setIsPresetModalVisible(true);
    } else {
      if (!linkedAccounts.accounts.paypal)
        return navigate("/manage-accounts/paypal");
      dispatch(
        joinLobby({
          matchId: matchMaking.joiningMatchId,
          consoleType: selectedConsole.consoleType,
        })
      );
      matchCreate();
    }
  };

  const checkConsoleSelected = () => {
    if (selectedConsole.type) return checkPlatformConnection();
    setShowConsoles(true);
  };

  const onPressCreate = () => {
    checkConsoleSelected();
    setDisable(true);
  };

  const createTournament = (presetName?: string) => {
    const tournamentData = {
      rounds: rounds.name,
      cubeWager: matchCubes,
      designatedSpectators: selectedSpectators,
      game: game?._id,
      gameType: gameType.type,
      tournamentType: tournaments.tournamentType,
      tournamentDate: new Date(),
      tournamentStatus: privacy,
      savePreset: presetName ? true : false,
      presetName: presetName,
      consoleType: selectedConsole.consoleType,
    };

    dispatch(
      createMainTournament({
        payload: { tournamentData },
        callback: () => {
          dispatch(clearDate());
          matchCreate();
        },
      })
    );
  };

  const handlePresetModalYes = (presetName: string) => {
    dispatch(resetCurrentMatch());
    setIsPresetModalVisible(false);
    if (screenStatus === "CREATE_TOURNAMENT")
      return createTournament(presetName);

    const data = {
      matchType: matchType,
      rounds: rounds.name,
      cubeWager: matchCubes,
      gameType: gameType.type,
      designatedSpectators: selectedSpectators,
      game: game?._id,
      tournamentType: "",
      scheduledTime: date,
      savePreset: true,
      presetName: presetName,
      tournamentStatus: privacy,
      isPrivate: isPrivate,
      consoleType: selectedConsole.consoleType,
    };
    dispatch(createMatch({ payload: JSON.stringify(data) })).then(() => {
      dispatch(clearDate());
      matchCreate();
    });
  };

  const handlePresetModalNo = () => {
    dispatch(resetCurrentMatch());

    setIsPresetModalVisible(false);
    if (screenStatus === "CREATE_TOURNAMENT") return createTournament();

    const data = {
      matchType: matchType === "CREATE_MATCHMAKING" ? "DUEL" : matchType,
      rounds: rounds.name,
      cubeWager: matchCubes,
      gameType: gameType.type,
      designatedSpectators: selectedSpectators,
      game: game?._id ? game?._id : game,
      tournamentType: "",
      scheduledTime: date,
      savePreset: false,
      presetName: "",
      tournamentStatus: privacy,
      isPrivate: isPrivate,
      consoleType: selectedConsole.consoleType,
    };
    dispatch(createMatch({ payload: JSON.stringify(data) })).then(() => {
      dispatch(clearDate());
      matchCreate();
    });
  };

  const matchCreate = () => {
    if (!linkedAccounts.accounts.paypal)
      return navigate("/manage-accounts/paypal");
    if (screenStatus === "JOIN_MATCH")
      return navigate(`/duels/lobby/${matchMaking.joiningMatchId}`);
    if (screenStatus === "CREATE_TOURNAMENT")
      return navigate("/duels/invite-others");
    if (gameType?.text === "1 vs 1") {
      navigate("/duels/challenge-others");
    } else {
      navigate("/duels/invite-others");
    }
  };

  const handleEditBest = () => {
    navigate(`/duels/how-many-rounds/${screenStatus}`);
  };
  const handleEditType = () => {
    navigate(`/duels/select-game-type/${screenStatus}`);
  };
  const handleEditWager = () => {
    navigate(`/duels/how-many-cubes/${screenStatus}`);
  };
  const handleEditSpectators = () => {
    setUserModal(true);
  };

  const handleEditTournamentType = () => {
    navigate(`/tournament/type/${screenStatus}`);
  };

  const renderStatus = (footer?: string) => {
    switch (screenStatus) {
      case "CREATE_MATCHMAKING":
        if (footer) return "Create Matchmaking";
        return "Matchmaking Overview";
      case "DUEL":
        if (footer) return "Create Duel";
        return "Duel Overview";
      case "CREATE_TOURNAMENT":
        if (footer) return "Create Tournament";
        return "Tournament Overview";
      case "JOIN_MATCH":
        if (footer) return "Join Match";
        return "Join Match";
      case "JOIN_PRESET":
        if (footer) return "Create Match";
        return "Create Match";
      case "OPPONENT_JOIN_MATCH":
      case "OPPONENT_JOIN":
        if (footer) return "Join Tournament";
        return "Tournament Overview";
    }
  };

  useEffect(() => {
    const callback = (data: any) => {
      dispatch(resetLoading());
      dispatch(setSelectedRound({ name: data.rounds }));
      dispatch(
        setSelectedGameType({
          type: data.gameType,
          text: gameTypeValues[data.gameType as gameTypes],
        })
      );
      dispatch(setMatchCubes(data.cubeWager));
      dispatch(setSelectedGame(data.game));
    };

    if (!paramMatchId || paramMatchId?.length === 0) {
      dispatch(resetLoading());
      return;
    }
    if (!isTournament) {
      const matchId = paramMatchId?.split(",")[0];
      dispatch(
        getMatchById({
          matchId: matchId,
          callback,
          err: () => {
            dispatch(resetLoading());
            toast.error(
              "The match you are looking for does not exist or has been deleted. Sending you back to the menu..."
            );
            setTimeout(() => {
              navigate("/");
            }, 400);
          },
        })
      );
    }
  }, [paramMatchId, urlMatchId]);

  const dateValue = date ? date : new Date();
  const formattedDate = dateValue.toLocaleString("en-US", {
    weekday: "short",
    month: "short",
    day: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });

  const onDecline = () => {
    if (screenStatus === "OPPONENT_JOIN") return navigate("/");
    dispatch(
      handleNotifications({
        payload: {
          actionData: notificationData.actionData
            ? notificationData.actionData
            : urlMatchId,
          actionType: notificationData.actionType
            ? notificationData.actionType
            : "OPPONENT_JOIN_MATCH",
          actionState: false,
        },
      })
    ).then(() => {
      navigate("/duels");
    });
  };

  return (
    <div className="mx-auto w-full  bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex   h-screen ">
      <div className="container flex flex-col gap-y-[20px] md:my-16 items-center max-md:p-5">
        <div
          className="  backdrop-blur-sm con rounded-[34px] p-10
        overflow-y-auto w-[calc(100%-176px)] min-h-[calc(100vh-160px)] max-md:w-[calc(100%-10px)]  max-md:h-[95vh]"
        >
          <div className="w-full rounded-t-[34px] flex items-center justify-between">
            <span className="text-3xl text-white items-center font-normal">
              {renderStatus()}
            </span>
            <div className="flex gap-6 items-center">
              <button
                onClick={() => setRulesModalOpen(true)}
                className=" text-primary text-center text-sm md:text-lg border-primary rounded-lg p-1 hover:text-primaryActive"
              >
                Match Rules
              </button>
              <button className=" w-[25px] h-[25px]" onClick={handleClose}>
                <img src={CloseIcon} alt="Close" />
              </button>
            </div>
          </div>
          <div className="flex flex-col items-center mt-10">
            <img
              className="md:w-[40vh] rounded-xl"
              src={gameBanner}
              alt="Game Banner"
            />

            <span className="text-white text-center mt-5">
              Load into a non-ranked {gameType?.text} match with your opponent.
              <br />
              Whoever wins the {rounds?.name} match(es) is the winner
            </span>
          </div>
          <div className="flex flex-col items-center gap-5 md:mt-[10px] max-md:mt-5">
            <span className="text-primary">{formattedDate}</span>
            {isTournament && (
              <div className={"flex max-md:flex-col w-full gap-7 max-md:gap-5 justify-center"}>
                <EditableRow
                  title="Tournament Type"
                  subtitle={tournaments.tournamentType}
                  onEdit={handleEditTournamentType}
                />
              </div>

            )}
            <div className={"flex max-md:flex-col md:w-4/5 max-md:w-full gap-7 max-md:gap-5 md:justify-between"}>
              <EditableRow
                title="Best"
                subtitle={rounds?.name}
                onEdit={handleEditBest}
              />
              <EditableRow
                title="Game Type"
                subtitle={gameType?.text}
                onEdit={handleEditType}
              />
            </div>
            <div className="flex max-md:flex-col md:w-4/5 max-md:w-full gap-7 max-md:gap-5 md:justify-between">
              <EditableRow
                title="Cube Wager"
                subtitle={`${parseInt(matchCubes) === 0 ? "Free Play" : `$${matchCubes}`}`}
                onEdit={handleEditWager}
              />
              <EditableRow
                title="Designated Spectators"
                subtitle={
                  selectedSpectators?.length <= 0
                    ? "No Spectators"
                    : `${selectedSpectators?.length} Spectators`
                }
                onEdit={handleEditSpectators}
              />
            </div>
          </div>
          <div className="flex flex-row justify-evenly items-center mt-8">
            <div className="flex flex-col gap-8 max-md:w-2/3">
              <ButtonDc
                text={renderStatus("footer")}
                action={onPressCreate}
                type="primary"
                disabled={disable}
              />
              {isTournamentOpponent && (
                <ButtonDc text={"Decline"} action={onDecline} type="error" />
              )}
            </div>
          </div>
        </div>
      </div>
      <ConsolModal
        visible={showConsoles}
        onPress={(item: any) => {
          if (isTournament) {
            dispatch(setTournamentConsole(item));
          } else {
            dispatch(setConsole(item));
          }
          setShowConsoles(false);
          checkPlatformConnection(item);
        }}
        data={game?.consoles}
        onClose={() => setShowConsoles(false)}
      />
      <PresetModal
        visible={isPresetModalVisible}
        onPressYes={handlePresetModalYes}
        onPressNo={handlePresetModalNo}
        onClose={() => {
          setIsPresetModalVisible(false);
          setDisable(false);
        }}
      />
      <PlatFormsModal
        visible={visible}
        data={data}
        onClose={() => setVisible(false)}
        onPress={(e) => onPress(e)}
      />
      <LoadingAnim
        loading={
          matchMaking.loader || notifications.loader || tournaments.loading
        }
      />
      {userModal && (
        <UsersModal
          data={user.friends}
          closeModal={() => setUserModal(false)}
          placeholder="Select from spectators"
          buttonText="Add"
          removeText="Remove"
          header="Designation Spectators"
          onPress={(data) => {
            setSelectedSpectators(data);
            dispatch(setSpectators(data));
          }}
          selected={selectedSpectators}
        />
      )}
      {rulesModalOpen && (
        <MatchRulesModal
          visible={rulesModalOpen}
          onClose={() => setRulesModalOpen(false)}
        />
      )}
    </div>
  );
};

export default DuelOverviewScreen;
