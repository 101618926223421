import { createSlice } from "@reduxjs/toolkit";
import { getMatchById, getNotifications, handleNotifications } from "./actions";
import { signOut } from "store/auth/actions";

interface NotificationSlice {
  notifications: any[];
  actionData: any;
  page: number;
  loader: boolean;
  err: boolean;
  notificationCount?: number;
  chatNotificationCount?: number;
}

const initialState: NotificationSlice = {
  notifications: [],
  actionData: {},
  page: 1,
  loader: false,
  err: false,
  notificationCount: 0,
  chatNotificationCount: 0,
};

export const notificationSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    setActionData: (state, action) => {
      state.actionData = { ...state.actionData, ...action.payload };
    },
    resetActionData: (state) => {
      state.actionData = {};
    },
    resetLoading: (state) => {
      state.loader = false;
    },
    setNotificationCount: (state, action) => {
      state.notificationCount = action?.payload;
    },
    setChatNotificationCount: (state, action) => {
      state.chatNotificationCount = action?.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNotifications.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getNotifications.fulfilled, (state, action) => {
      action.payload?.notifications?.forEach((notification: any) => {
        const index = state.notifications.findIndex(
          (item) => item._id === notification._id
        );
        if (index !== -1) {
          if (
            state.notifications[index].actionState !== notification.actionState
          ) {
            state.notifications[index] = notification;
          }
          return;
        }
        state.notifications.push(notification);
      });
      state.page += 1;

      state.loader = false;
    });
    builder.addCase(getNotifications.rejected, (state) => {
      state.err = true;
      state.loader = false;
    });
    builder.addCase(handleNotifications.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(handleNotifications.fulfilled, (state, action) => {
      state.loader = false;
    });
    builder.addCase(handleNotifications.rejected, (state) => {
      state.err = true;
      state.loader = false;
    });
    builder.addCase(signOut.fulfilled, (state) => {
      return initialState;
    });
  },
});

export default notificationSlice.reducer;
export const {
  setActionData,
  resetActionData,
  resetLoading,
  setNotificationCount,
  setChatNotificationCount,
} = notificationSlice.actions;
