import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "store/hooks";
import { RootState } from "store/index";
import { useAppDispatch } from "store/hooks";

import { subscribeToTopic } from "store/fcm/actions";
import { closeMatch, leaveLobby } from "store/matchMaking/actions";
import { enableStaking } from "store/stake/action";
import { toast } from "react-toastify";

import { matchStatuses } from "types/constants";

import {
  resetBackgroundMatch,

} from "store/matchMaking/matchMakingSlice";

import ButtonDc from "components/Button";
import defaultImage from "assets/default_profile_yellow.png";
import CountdownTimer from "components/CountdownTimer";
import Refresh from "assets/refresh_icon.svg";
import LoadingAnim from "components/Loader";
import StreamModal from "components/StreamModal";
import CustomSwitch from "components/Switch";
import { cancelGameChallenge, endGameChallenge, getGameChallenge, leaveGameChallenge, setReadyForChallenge, startGameChallenge } from "store/gameHub/action";
import { playerTypes, resetCurrentGame, resetGameHubSlice, updatePlayerList } from "store/gameHub/gameHubSlice";
import { games } from "../../../types/games";

const GameLobbyScreen = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  let { matchId, stream } = useParams();
  const { currentGame, loader } = useAppSelector((state: RootState) => state.gameHub)

  const loaderStaking = useAppSelector((state) => state.stake).loading;
  const userId = useAppSelector((state: RootState) => state.auth).user._id;
  // const userId = useAppSelector((state: RootState) => state.user._id);
  const fcmData = useAppSelector((state) => state.fcm);
  const currentMatch = useAppSelector(
    (state: RootState) => state.currentMatch
  ).currentMatch;
  const isSuspended = useAppSelector(
    (state: RootState) => state.user
  ).user?.isSuspended;

  const [isStreamModalVisible, setIsStreamModalVisible] = useState(false);
  const [streamSelected, setStreamSelected] = useState(false);
  const [countdown, setCountdown] = useState(1799);
  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const inactivityTimeoutSeconds = 1799;
  const matchStatus = currentGame.challengeStatus;

  const matchScheduled = matchStatus === matchStatuses.SCHEDULED;
  const matchWaiting = matchStatus === matchStatuses.WAITING_TO_START;

  const players = currentGame?.players ?? [];
  const readyUsers = currentGame?.readyUsers ?? [];
  const hostPlayerId = currentGame?.host;
  const opponentPlayerId = players.filter((player: playerTypes) => player._id !== hostPlayerId) ?? null;
  const gameData = games.find((game: any) => game.id === currentGame?.gameId._id);

  const teamOne = players.find((player: playerTypes) => player?._id === hostPlayerId);
  const teamTwo = players.find((player: any) => player?._id === opponentPlayerId[0]?._id);


  const teamOneReady = teamOne && readyUsers.includes(teamOne._id) ? [teamOne] : [];
  const teamTwoReady = teamTwo && readyUsers.includes(teamTwo._id) ? [teamTwo] : [];


  const isUserReady = readyUsers.includes(userId);
  const isUserHost = hostPlayerId === userId;
  const isFreePlay = currentMatch.cubeWager === 0;

  const isAllJoined = teamOne && teamTwo ? true : false;
  const allReady = teamOneReady.length > 0 && teamTwoReady.length > 0;

  const startMatchButton = isUserHost;
  const isStakingEnabled = currentMatch.stakingEnabled === true;

  const matchTime = new Date(
    matchScheduled ? currentGame.scheduledTime : currentGame.createdAt
  ).getTime();
  const matchCreationTime = matchTime ? matchTime : new Date().getTime();

  const handleChallengeButton = () => {
    navigate(`/gaming/challenge/${matchId}`);
  };

  const updateUserReadyState = () => {
    const updatedPlayerList = players.map((player) => {
      if (player._id === userId) {
        return { ...player, isReady: !isUserReady };
      }
      return player;
    });
    dispatch(updatePlayerList(updatedPlayerList));
  };

  const handleStartMatch = () => {
    const callback = () => {
      dispatch(
        getGameChallenge({
          matchId,
          callback: () => {
            { isSuspended ? (toast.warning('Account Suspended')) : navigate(`/gaming/game-starting/${matchId}`, { state: { gameData } }); }
          },
        })
      );
    };

    const handleMatchEnd = () => {
      //TODO navigate to vote screen
    };

    if (matchStatus === matchStatuses.ONGOING) {
      dispatch(endGameChallenge({ matchId, callback: handleMatchEnd }));
      return;
    }

    dispatch(startGameChallenge({ matchId, callback }));
  };
  const handlePlayerReady = () => {
    updateUserReadyState();
    dispatch(
      setReadyForChallenge({
        matchId,
      })
    );
  };
  const handleStreamModalNo = () => {
    navigate(`/gaming/game-lobby/${matchId}/${false}`);
    setIsStreamModalVisible(false);
    handlePlayerReady();
    setStreamSelected(true);
  };

  const onTimerEnd = () => {

    onRefresh();
  };
  const handleReadyButton = () => {
    // if (!stream) navigate(`/streaming/${matchId}/platform`);
    // else handlePlayerReady();
    // setStreamSelected(true);
    handlePlayerReady();
  };

  const handleStartButtonPress = () => {
    handleStartMatch();
    setStreamSelected(false);
  };

  const handleCancelMatch = () => {

    dispatch(
      cancelGameChallenge({
        matchId: matchId,

        callback: () => {
          toast.success("Match cancelled successfully");
          navigate("/");
          dispatch(resetCurrentGame());
          dispatch(resetBackgroundMatch());
          dispatch(resetGameHubSlice());
          onRefresh();
        },
      })
    );
  };
  const handleLeaveMatch = () => {
    dispatch(
      leaveGameChallenge({
        matchId: matchId,
        callback: () => {
          dispatch(resetCurrentGame());
          dispatch(resetBackgroundMatch());
          dispatch(resetGameHubSlice());
          toast.success("you have successfully leave the match.");
          navigate("/");
        },
      })
    );
  };

  const renderPlayer = (player: playerTypes, index: number) => (
    <div
      key={index}
      style={{
        backgroundImage: !!player.profilePicture
          ? `url(${player.profilePicture})`
          : `url(${defaultImage})`,
      }}
      className="min-w-[48px] min-h-[48px] rounded-full border-primary border-2 bg-no-repeat bg-contain"
    ></div>
  );

  const renderTeamPlayer = (player: any, index: number) => (
    <div
      className="flex flex-col mx-2 justify-center items-center "
      key={index}
    >
      <div
        className="w-[48px] h-[48px] rounded-ful bg-no-repeat bg-center bg-contain rounded-full"
        style={{
          backgroundImage: !!player.profilePicture
            ? `url(${player.profilePicture})`
            : `url(${defaultImage})`,
        }}
      />
      <span className="text-white text-[14px] ">{player.username}</span>
    </div>
  );

  useEffect(() => {
    if (stream && !isUserReady) return handlePlayerReady();
  }, [stream]);

  useEffect(() => {
    dispatch(getGameChallenge({ matchId }));
    dispatch(subscribeToTopic({ topic: matchId }));
    console.log("subscribed to topic ", matchId);
  }, [matchId]);

  useEffect(() => {
    if (matchStatus === matchStatuses.ONGOING) {
      { isSuspended ? (toast.warning('Account Suspended')) : navigate(`/gaming/game-starting/${matchId}`, { state: { gameData } }); }
    }
  }, [currentGame]);

  useEffect(() => {
    if (fcmData?.data?.type === "PLAYER_READY_STATE" || "START_MATCH")
      dispatch(getGameChallenge({ matchId }));

    if (fcmData.data.type === "CANCEL_MATCH") {
      return onRefresh;
    }
    if (fcmData.data.type === "CANCEL_CHALLENGE") {
      toast.warning(fcmData.data.message)
      return navigate("/");
    }
  }, [fcmData]);

  useEffect(() => {
    const updateCountdown = () => {
      if (isFutureMatch) return;
      setCountdown((prevCountdown) => {
        const newCountdown = Math.max(0, prevCountdown - 1);
        if (newCountdown === 0) {
          clearInterval(timerId);
          if (!allReady) {

            dispatch(
              closeMatch({
                payload: { matchId: matchId },
                callback: () => {
                  toast.error("Match canceled due to inactivity");
                  dispatch(resetCurrentGame());
                  dispatch(resetBackgroundMatch());
                  dispatch(resetGameHubSlice());
                  onRefresh();
                },
              })
            );
          }
        }
        return newCountdown;
      });
    };

    const timerId = setInterval(updateCountdown, 1000);

    updateCountdown();

    return () => clearInterval(timerId);
  }, [dispatch, allReady, matchId]);

  useEffect(() => {
    if (hostPlayerId) return;
    const now = new Date().getTime();
    const timeElapsed = Math.floor((now - matchCreationTime) / 1000);
    const timeLeft = Math.max(0, inactivityTimeoutSeconds - timeElapsed);
    setCountdown(timeLeft);
  }, [dispatch, allReady, matchId]);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };
  const scheduledTime = new Date(currentGame.scheduledTime);
  const currentTime = new Date();
  const isFutureMatch = scheduledTime > currentTime;

  const onRefresh = () => {
    dispatch(getGameChallenge({ matchId }));
  };

  useEffect(() => {
    window.onbeforeunload = () => {
      return "";
    };
    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  const handleSwitchChange = () => {
    dispatch(
      enableStaking(
        {
          payload: { matchId: matchId }, callback: () => {

            setIsSwitchOn(true);
          }
        }),
    );
  };

  return (
    <div className="mx-auto w-full bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex min-h-screen h-full">
      <div className="container flex flex-col gap-y-[20px] md:mb-16 p-[20px] gap-3 gap-x-4 items-center justify-center">
        <div
          className="  backdrop-blur-sm con rounded-[34px] p-10 flex flex-col justify-center
        w-[calc(100%-176px)] min-h-[calc(100vh-160px)] max-md:w-[calc(100%-10px)] gap-3 max-md:h-[95vh]"
        >
          <div className="w-full rounded-t-[34px] flex items-center justify-center mb-10">
            <span className="text-3xl text-white items-center font-normal">
              Lobby
            </span>
            <button
              className=" w-[25px] h-[25px] absolute right-10"
              onClick={onRefresh}
            >
              <img src={Refresh} alt="Close" />
            </button>
          </div>
          <div className="justify-center items-center text-center">
            {isFutureMatch && (
              <div className="flex items-center justify-center my-3">
                <CountdownTimer
                  until={currentGame.scheduledTime}
                  onEnd={onTimerEnd}
                />
              </div>
            )}
            {countdown < 1799 &&
              !allReady &&

              !isFutureMatch && (
                <span className="text-l text-white items-center font-normal text-center">
                  Match will automatically cancel after {formatTime(countdown)},{" "}
                  <br />
                  if players are not available
                </span>
              )}
          </div>
          <div className="flex justify-center gap-2">
            {players.map(renderPlayer)}
          </div>
          <div className="flex justify-center gap-2 w-full min-h-[74px]">
            <div className="w-full max-w-[367px] h-full rounded-[16px] bg-borderColor flex justify-center items-center px-[30px] py-[8px] max-md:overflow-x-auto">
              {teamOneReady.length === 0 && (
                <span className="text-white text-[20px] max-md:text-[12px]">
                  Waiting for players
                </span>
              )}
              {teamOneReady.map(renderTeamPlayer)}
            </div>
            <div className="w-full max-w-[367px] h-full rounded-[16px] bg-borderColor flex justify-center items-center px-[30px] py-[8px] max-md:overflow-x-auto">
              {teamTwoReady.length === 0 && (
                <span className="text-white text-[20px] max-md:text-[12px]">
                  Waiting for players
                </span>
              )}
              {teamTwoReady.map(renderTeamPlayer)}
            </div>

          </div>
          <div className="mt-[40px] mb-[30px] flex flex-col gap-y-[20px]">
            <div className="flex justify-center gap-6">

              <ButtonDc
                text="Challenge"
                action={handleChallengeButton}
                style={{ margin: 0 }}

              />
            </div>

            <div className="flex justify-center">
              <ButtonDc
                text={isUserReady ? "Not ready" : "I am ready"}
                action={handleReadyButton}
                type="primary"
                disabled={isFutureMatch}
              />

            </div>
          </div>
          {!isFreePlay && <div className="flex justify-center">
            <span className=" text-white justify-center text-center text-xs md:text-sm w-full max-w-[350px]">
              ⚠️ Once Staking is enabled, players can no longer leave the match, cancel, or disable Staking.
            </span>
          </div>}
          {isUserHost && !isFreePlay && (
            <div className="flex justify-center">

              <div className="flex bg-borderColor h-12 rounded-xl items-center px-7 justify-between w-full max-w-[350px]">
                <span className=" text-white text-sm md:text-lg">Staking</span>
                <CustomSwitch
                  checked={isSwitchOn}
                  onChange={handleSwitchChange}
                  disabled={!isAllJoined || isStakingEnabled}
                />
              </div>
            </div>
          )}
          {isStakingEnabled && !isUserHost && !isFreePlay && (
            <div className="flex justify-center">
              <div className="flex bg-borderColor h-12 rounded-xl items-center px-7 justify-center w-full max-w-[350px]">
                <span className=" text-white text-sm md:text-lg">Staking enabled</span>

              </div>
            </div>
          )}
          <div className="flex justify-center gap-2 w-full">
            <div className="w-full max-w-[350px] h-[74px] rounded-[16px] bg-borderColor flex justify-center items-center px-[30px] py-[8px] flex-col">
              <span className="text-white text-[20px]  ">
                {!allReady ? "Waiting for players" : "Waiting to start"}
              </span>

            </div>
          </div>
          <StreamModal
            visible={isStreamModalVisible}
            onPressYes={() => navigate(`/streaming/${matchId}/platform`)}
            onPressNo={handleStreamModalNo}
            onClose={() => setIsStreamModalVisible(false)}
          />
          {startMatchButton && (
            <div className="flex justify-center">
              <ButtonDc
                text={"Start match"}
                action={handleStartButtonPress}
                type="primary"
                disabled={!allReady}
              />
            </div>
          )}
          {isUserHost ? (
            <div className="flex justify-center">
              <ButtonDc
                text="Cancel game"
                action={handleCancelMatch}
                type="error"
                disabled={isStakingEnabled}
              />
            </div>
          ) : (
            <div className="flex justify-center">

              <ButtonDc
                text="Leave match"
                action={handleLeaveMatch}
                type="error"
                disabled={isStakingEnabled}
              />

            </div>
          )}
        </div>
      </div>
      <LoadingAnim loading={loader} />
    </div>
  );
};

export default GameLobbyScreen;
