import {
  checkStreamApi,
  getMatchChatApi,
  getStreamApi,
  sendMatchMessageApi,
  voteMatchApi,
} from "api/matchMacking.api";
import {
  GetGamesApi,
  challengeToMatchApi,
  closeMatchApi,
  createMatchApi,
  getCurrentMatchApi,
  inviteToMatchApi,
  joinLobbyApi,
  leaveLobbyApi,
  searchGamesApi,
  searchUsersApi,
} from "../../api/common.api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

interface WithCallback {
  payload?: any;
  callback?: (data: any) => void;
}

export const getGames = createAsyncThunk<any, any>(
  "match/getGames",
  async (payload, { rejectWithValue }) => {
    try {
      const data: any = await GetGamesApi(payload);
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const createMatch = createAsyncThunk<any, any>(
  "match/createMatch",
  async ({ payload, callback }, { rejectWithValue }) => {
    try {
      const data: any = await createMatchApi(payload);
      callback?.(data);
      return data;
    } catch (error: any) {
      toast.error(error.message);
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const inviteToMatch = createAsyncThunk<any, any>(
  "match/invite-to-match",
  async (payload, { rejectWithValue }) => {
    try {
      const data: any = await inviteToMatchApi(payload);
      toast.success(data.message);
      return data;
    } catch (error: any) {
      toast.error(error.data.message);
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const challengeToMatch = createAsyncThunk<any, any>(
  "match/challenge-to-match",
  async (payload, { rejectWithValue }) => {
    try {
      const data: any = await challengeToMatchApi(payload);
      toast.success(data.message);
      return data;
    } catch (error: any) {
      toast.error(error.message);
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const joinLobby = createAsyncThunk<any, any>(
  "match/joinLobby",
  async (payload, { rejectWithValue }) => {
    try {
      const data: any = await joinLobbyApi(payload);
      return data;
    } catch (error: any) {
      toast.error(error.message);
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const voteMatch = createAsyncThunk<any, any>(
  "match/voteMatch",
  async ({ payload, callback }, { rejectWithValue }) => {
    try {
      const data: any = await voteMatchApi(payload);
      callback?.(data);
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const checkStream = createAsyncThunk<any, any>(
  "match/checkStream",
  async ({ payload, callback, rejectHandler }, { rejectWithValue }) => {
    try {
      const data: any = await checkStreamApi(payload);
      callback?.(data);
      return data;
    } catch (error: any) {
      rejectHandler?.();
      toast.error(error.data?.message);
      if (!error.data?.message) {
        throw error;
      }
      return rejectWithValue(error.data?.message);
    }
  }
);

export const closeMatch = createAsyncThunk<any, WithCallback>(
  "match/closeMatch",
  async ({ payload, callback }, { rejectWithValue }) => {
    try {
      const data: any = await closeMatchApi(payload);
      callback?.(data);
      return data;
    } catch (error: any) {
      toast.error(error?.data.message);
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const searchGames = createAsyncThunk<any, WithCallback>(
  "match/searchGames",
  async ({ payload, callback }, { rejectWithValue }) => {
    try {
      const data: any = await searchGamesApi(payload.query, payload.page);
      callback?.(data);
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const searchUsers = createAsyncThunk<any, WithCallback>(
  "match/searchUsers",
  async ({ payload, callback }, { rejectWithValue }) => {
    try {
      const data: any = await searchUsersApi(payload.query, payload.page);
      callback?.(data);
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const leaveLobby = createAsyncThunk<any, WithCallback>(
  "match/leaveLobby",
  async ({ payload, callback }, { rejectWithValue }) => {
    try {
      const data: any = await leaveLobbyApi(payload);
      callback?.(data);
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getCurrentMatchState = createAsyncThunk<any, WithCallback>(
  "match/currentMatchState",
  async ({ callback }, { rejectWithValue }) => {
    try {
      const data: any = await getCurrentMatchApi();
      callback?.(data);
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getMatchChat = createAsyncThunk<any, any>(
  "match/getMatchChat",
  async (payload, { rejectWithValue }) => {
    try {
      const data: any = await getMatchChatApi(payload);
      const messagesReversed = {
        ...data,
        messages: data.messages.reverse(),
      };
      return messagesReversed;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const sendMatchMessage = createAsyncThunk<any, WithCallback>(
  "match/send-match-message",
  async ({ payload, callback }, { rejectWithValue }) => {
    try {
      const data: any = await sendMatchMessageApi(payload);
      callback?.(data);
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getStream = createAsyncThunk<any, any>(
  "match/getStream",
  async ({ payload, callback, rejectHandler }, { rejectWithValue }) => {
    try {
      const data: any = await getStreamApi(payload);
      callback?.(data);
      return data;
    } catch (error: any) {
      rejectHandler?.();
      toast.error(error.data?.message);
      if (!error.data?.message) {
        throw error;
      }
      return rejectWithValue(error.data?.message);
    }
  }
);
