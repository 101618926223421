import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "store/hooks";
import { AppDispatch, RootState } from "store/index";
import { useDispatch } from "react-redux";
import { createGameChallenge, getGameChallenge, joinGameChallenge } from "store/gameHub/action";
import { resetCurrentGame, setSelectedGame } from "store/gameHub/gameHubSlice";
import { games } from "types/games";
import { cleanFcmData } from "store/fcm/fcmSlice";
import { resetStreamingMethod } from "store/streaming/streamingSlice";
import { subscribeToTopic } from "store/fcm/actions";

import CloseIcon from "assets/close-button.svg";
import ButtonDc from "components/Button";
import LoadingAnim from "components/Loader";

interface EditableRowProps {
  title: string;
  subtitle: string;
  onEdit: () => void;
  spectators?: boolean;
}

const EditableRow: React.FC<EditableRowProps> = ({
  title,
  subtitle,
  onEdit,
  spectators,
}) => {
  const params = useParams();
  const urlMatchId = params?.matchId;
  const user = useAppSelector((state: RootState) => state.auth);

  return (
    <div className="flex flex-row bg-borderColor items-center justify-between gap-5  w-1/2 h-16 rounded-lg p-2  max-md:w-11/12">
      <div className="flex flex-col justify-between gap-1">
        <span className="text-white">{title}</span>
        <span className="text-placeholder text-xs">{subtitle}</span>
      </div>

      {!urlMatchId && <button
        className="flex w-[150px] h-[35px] rounded-lg p-1 items-center justify-center bg-primary hover:bg-primaryActive max-md:w-1/5"
        onClick={onEdit}
      >
        <span>Edit</span>
      </button>}

    </div>
  );
};

const GameOverviewScreen = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch: AppDispatch = useDispatch();
  const gameHub = useAppSelector((state: RootState) => state.gameHub);
  const loader = gameHub?.loader;
  const currentGame = gameHub?.currentGame;
  const gameData = games.find((game: any) => game.id === currentGame?.gameId._id);
  const urlMatchId = params?.matchId;
  const gameBanner = urlMatchId ? gameData?.image : gameHub?.selectedGame?.image;
  const matchCubes = urlMatchId ? currentGame?.cubeWager : gameHub?.matchCubes;
  const title = urlMatchId ? currentGame?.gameId.name : gameHub?.selectedGame?.title
  const date = new Date(); //TODO
  const handleClose = () => {
    navigate("/gaming");
  };

  useEffect(() => {
    urlMatchId && dispatch(getGameChallenge({
      matchId: urlMatchId, callback: (gameData) => {
        dispatch(
          setSelectedGame({
            id: gameData?.id,
            title: gameData?.title,
            route: gameData?.route,
            gameType: gameData?.gameType,
            isLandscape: gameData?.isLandscape,
            image: gameData?.image,
            gameLogo: gameData?.gameLogo,
            backgroundImage: gameData?.backgroundImage,
            textColor: gameData?.textColor,
            textColor2: gameData?.textColor2,
          })
        );
      }
    }));

  }, [urlMatchId]);

  const onPressCreate = () => {
    dispatch(resetCurrentGame())
    const data = {
      challengeType: gameHub.selectedGame?.gameType,
      gameId: gameHub.selectedGame?.id,
      scheduledTime: new Date(),
      cubeWager: matchCubes,
    };
    dispatch(
      createGameChallenge({
        payload: JSON.stringify(data), callback: (game) => { dispatch(cleanFcmData()); dispatch(resetStreamingMethod()); navigate(`/gaming/challenge/${game.challengeId}`); }
      })
    );
  };

  const onPressJoin = () => {
    dispatch(
      joinGameChallenge({
        matchId: urlMatchId,
        callback: () => {
          return navigate(`/gaming/game-lobby/${urlMatchId}`);
        },
      })
    );

  };
  const handleEditWager = () => {
    navigate(`/gaming/cubes/${'overview'}`);
  };

  const dateValue = date ? date : new Date();
  const formattedDate = dateValue.toLocaleString("en-US", {
    weekday: "short",
    month: "short",
    day: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });

  return (
    <div className="mx-auto w-full  bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex   h-screen ">
      <div className="container flex flex-col gap-y-[20px] md:my-16 items-center max-md:p-5">
        <div
          className="  backdrop-blur-sm con rounded-[34px] p-10
        overflow-y-auto w-[calc(100%-176px)] min-h-[calc(100vh-160px)] max-md:w-[calc(100%-10px)]  max-md:h-[95vh]"
        >
          <div className="w-full rounded-t-[34px] flex items-center justify-between">
            <span className="text-3xl text-white items-center font-normal">
              Challenge Overview
            </span>
            <div className="flex gap-6 items-center">

              <button className=" w-[25px] h-[25px]" onClick={handleClose}>
                <img src={CloseIcon} alt="Close" />
              </button>
            </div>
          </div>
          <div className="flex flex-col items-center mt-10">
            <span className="text-white text-center font-aldo text-4xl mb-5">{title}</span>
            <img
              className="md:h-[30vh] rounded-xl"
              src={gameBanner}
              alt="Game Banner"
            />
            <span className="text-white text-center mt-5">
              Enter a competitive 1 vs 1 match with your opponent.
              <br />
              The player who achieves the highest score at the end of the game will be declared the winner and take home the prize.
            </span>
          </div>
          <div className="flex flex-col items-center gap-5 md:mt-[10px] max-md:mt-5">
            <span className="text-primary">{formattedDate}</span>
            <div className="flex max-md:flex-col md:w-4/5 max-md:w-full gap-7 max-md:gap-5 md:justify-center">
              <EditableRow
                title="Cube Wager"
                subtitle={`$${matchCubes}`}
                onEdit={handleEditWager}
              />
            </div>
          </div>
          <div className="flex flex-row justify-evenly items-center mt-8">
            <div className="flex flex-col gap-8 max-md:w-2/3">
              {!urlMatchId ? (<ButtonDc
                text='Create Challenge'
                action={onPressCreate}
                type="primary"
              />) : (
                <ButtonDc
                  text='Join Challenge'
                  action={onPressJoin}
                  type="primary"
                />)}
            </div>
          </div>
        </div>
      </div>
      <LoadingAnim loading={loader} />
    </div>
  );
};

export default GameOverviewScreen;
