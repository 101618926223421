import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { AppDispatch } from "store/index";
import { toast } from "react-toastify";
import { digitalPayment } from "store/payments/action";
import { capturePayment } from "store/wallet/action";
import { setAmount } from "store/wallet/walletSlice";
import LoadingAnim from "components/Loader";
import ButtonDc from "components/Button";

import { ReactComponent as PayPalIcon } from "assets/paypal_icon.svg";
import WalletIcon from "assets/walletIcon.png";
import CloseIcon from "assets/close-button.svg";

export default function TopUpFundsScreen({ }) {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useAppDispatch();
  const userId = useAppSelector((state) => state.user).user?._id;
  const { balance, loader } = useAppSelector((state) => state.wallet);
  const [value, setValue] = useState("");

  const onValueChange = (e: any) => {
    setValue(e.target.value);
  };

  const onCancel = () => {
    navigate("/wallet/main");
  };

  const handleTopupWallet = () => {
    dispatch(
      digitalPayment({
        payload: {
          value: value,
          payId: userId,
          redirectUrl: `${process.env.REACT_APP_DIGITAL_PAYMENT_URL}wallet/top-up-funds/${userId}`,
        },
        callback: (result: any) => {
          window.location.href = result.href;
        },
      })
    );
  };

  useEffect(() => {
    const token = window.location?.search
      ?.split("&token=")?.[1]
      ?.split("&")?.[0];
    if (!token) return;
    console.log("CAPTURE PAYMENT");
    onPayPaypal(token);
  }, []);

  const onPayPaypal = (token: any) => {
    dispatch(
      capturePayment({
        payload: {
          orderId: token,
          isTopup: true,
        },
        callback: handleSuccessTopUp,
      })
    );
  };

  const handleSuccessTopUp = () => {
    toast.success("Top up successful");
    onCancel();
  };

  const onContinue = () => {
    // if (balance < Number(value)) return toast.warning("Insufficient Balance!");
    if (isNaN(Number(value))) return toast.warn("Invalid amount!");
    dispatch(setAmount(value));
    handleTopupWallet();
  };

  return (
    <div className="mx-auto w-full  bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex flex-col  h-screen ">
      <div className="container flex flex-col gap-y-[20px] md:my-16 max-md:p-5 items-center">
        <div
          className="  backdrop-blur-sm con rounded-[34px] p-10
        overflow-y-auto w-[calc(100%-176px)] h-[calc(100vh-160px)] max-md:w-[calc(100%-10px)]  max-md:h-[95vh]"
        >
          <div className="flex flex-col gap-[1em]">
            <div className="w-full rounded-t-[34px] flex items-center justify-between">
              <div className="flex flex-row gap-4 items-center justify-center">
                <span className="text-3xl text-white items-center font-normal max-md:text-xl">
                  Top Up Funds
                </span>
              </div>
              <button
                className=" w-[25px] h-[25px] absolute right-10"
                onClick={onCancel}
              >
                <img src={CloseIcon} alt="Close" />
              </button>
            </div>
          </div>

          <div className="flex flex-col items-center justify-center gap-8  max-md:flex-col ">
            <div className="flex flex-col items-center justify-center p-10 my-4 gap-1 border-2 w-full desktop:w-1/3 md:w-1/2 border-primary rounded-2xl">
              <img src={WalletIcon} alt="wallet" />
              <span className="text-white text-[26px]">Your Balance</span>
              <span className="text-white text-[26px]">
                {balance ? `$ ${balance.toFixed(2)}` : `$ 0`}
              </span>
            </div>
            <span className="text-white text-xl max-md:text-lg">
              Enter the amount you want to add to your wallet. Your balance will
              be updated after the transaction is complete.
            </span>
            <div className="flex flex-col items-center justify-center">
              <input
                className="bg-inputGray rounded-lg h-16 text-center border-inputGray text-[22px] text-white md:w-[20vw]"
                placeholder="$ 00.00"
                value={value}
                onChange={(e) => onValueChange(e)}
              />
            </div>
            <ButtonDc
              icon={<PayPalIcon />}
              text="Top Up"
              type={"primary"}
              action={onContinue}
            />
          </div>
        </div>
      </div>
      <LoadingAnim loading={loader} />
    </div>
  );
}
