import { ReactComponent as BellIcon } from "assets/bell-icon.svg";
import { ReactComponent as HamburgerIcon } from "assets/hamberger-icon.svg";
import { ReactComponent as ChatIcon } from "assets/sideBar/chats.svg";
import { ReactComponent as GamingIcon } from "assets/sideBar/gaming.svg";
import { ReactComponent as HomeIcon } from "assets/sideBar/home.svg";
import { ReactComponent as Wagering } from "assets/sideBar/stake.svg";
import { ReactComponent as SteamingIcon } from "assets/sideBar/streaming.svg";
import Logo from "assets/weblogo.svg";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "./../store/hooks";
import { AppDispatch, RootState } from "./../store/index";
import ImageHolder from "./ImagePlaceholder";
import { handleChatNotificationCount, handleNotificationCount } from "utils/noitificationHandler";

interface sideBarProps {
  onOptions?: () => void;
  onNotifications?: () => void;
}

const Sidebar: React.FC<sideBarProps> = ({ onOptions, onNotifications }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const user = useAppSelector((state: RootState) => state.auth);
  const { notificationCount, chatNotificationCount } = useAppSelector((state: RootState) => state.notification);
  const dispatch: AppDispatch = useAppDispatch();
  const userData = useAppSelector((state: RootState) => state.user?.user);
  const isLoggedIn = user?.userToken;

  const isHome = location.pathname === "/";
  const isWagering = location.pathname === "/wagering";
  const isStreaming = location.pathname === "/stream/hub";
  const isChat = location.pathname === "/communication/main";
  const isGame = location.pathname === "/gaming";
  const isOptions = location.pathname === "/options";
  const isNotifications = location.pathname === "/notifications";
  const isMobile = window.innerWidth < 768;

  const theme =
    "text-white text-[14px] font-bold rounded-full flex flex-col justify-center items-center w-[62px] h-[62px] md:w-[72px] md:h-[72px] bg-testColor hover:text-primary transition-colors ";
  const activeTheme = theme + " !text-primary ";

  const onHomePress = () => {
    if (isLoggedIn) return navigate("/");
    navigate("/login");
  };
  const onDuelsPress = () => {
    if (isLoggedIn) return navigate("/wagering");
    navigate("/login");
  };
  const onStreamingPress = () => {
    if (isLoggedIn) return navigate("/stream/hub");
    navigate("/login");
  };
  const onChatPress = () => {
    if (isLoggedIn) {
      handleChatNotificationCount(-1);
      navigate("/communication/main");
      return
    }
    navigate("/login");
  };
  const onGamePress = () => {
    if (isLoggedIn) return navigate("/gaming");
    navigate("/login");
  };
  const onOptionsPress = () => {
    if (isLoggedIn) {
      onOptions?.();
      return;
    }
    navigate("/login");
  };
  const onNotificationsPress = () => {
    if (isLoggedIn) {
      onNotifications?.();
      handleNotificationCount(-1);
      return;
    }
    navigate("/login");
  };
  const onProfileClick = () => {
    if (isLoggedIn) {
      return navigate("/profile");
    }
    navigate("/login");
  };
  if (isMobile)
    return (
      <>
        <div className="flex justify-between items-center p-4 h-[60px]  backdrop-blur-2xl con fixed top-0 left-0 w-screen">
          <div className="flex flex-row items-center gap-2">
            {user?.userToken ? (
              <>
                <button onClick={onProfileClick}>
                  <ImageHolder
                    uri={userData?.profilePicture}
                    className="flex w-[50px] h-[50px] rounded-full "
                  />
                </button>

                <div className="relative">
                  <span className="text-l font-medium text cursor-pointer">
                    Hi, {userData?.username}
                  </span>
                </div>
              </>
            ) : (
              <button
                className="p-2 rounded-lg bg-primaryActive w-[128px] h-[38px] flex items-center justify-center"
                onClick={onProfileClick}
              >
                <span className="text-sm ">Sign in</span>
              </button>
            )}
          </div>
          <div className="flex gap-x-[12px] items-center">
            <button onClick={onOptionsPress}>
              <HamburgerIcon className="max-w-[16px]" color="white" />
            </button>
            <button onClick={onNotificationsPress}>
              <BellIcon className="max-w-[16px]" color="white" />
              {!!notificationCount && (
                <div className="bg-red-600 rounded-full w-5 h-5 items-center justify-center flex fixed top-3 right-2">
                  <span className="text-white text-xs">{notificationCount > 9 ? "9+" : notificationCount}</span>
                </div>
              )}
            </button>
          </div>
        </div>
        <div className="flex justify-around items-center p-2 bg-borderColor h-[70px] fixed bottom-0 left-0 w-screen z-10">
          <button
            className={isHome ? activeTheme : theme}
            onClick={onHomePress}
          >
            <HomeIcon className="max-w-[28px]" />
          </button>
          <button
            className={isWagering ? activeTheme : theme}
            onClick={onDuelsPress}
          >
            <Wagering className="max-w-[28px]" />
          </button>
          <button
            className={isGame ? activeTheme : theme}
            onClick={onGamePress}
          >
            <GamingIcon className="max-w-[28px]" />
          </button>
          <button
            className={isStreaming ? activeTheme : theme}
            onClick={onStreamingPress}
          >
            <SteamingIcon className="max-w-[28px]" />
          </button>
          <button
            className={isChat ? activeTheme : theme}
            onClick={onChatPress}
          >
            <ChatIcon className="max-w-[28px]" />
            {!!chatNotificationCount && (
              <div className="bg-red-600 rounded-full w-5 h-5 items-center justify-center flex absolute mb-5 ml-5 ">
                <span className="text-white text-xs">{chatNotificationCount > 9 ? "9+" : chatNotificationCount}</span>
              </div>
            )}
          </button>
        </div>
      </>
    );

  return (
    <div className="left-0 min-w-[176px] bg-auto bg-left h-screen py-[40px]">
      <div className="flex-col flex justify-between items-center border-r-[1px] border-borderColor h-full">
        <div className="flex flex-col gap-[2vh] items-center">
          <button className="z-40" onClick={onHomePress}>
            <img src={Logo} alt="" className="max-w-[100px]" />
          </button>
          <button
            className={isHome ? activeTheme : theme}
            onClick={onHomePress}
          >
            <HomeIcon className="max-w-[30px]" />
            <span className="text-[12px] font-medium mt-1">Home</span>
          </button>
          <button
            className={isWagering ? activeTheme : theme}
            onClick={onDuelsPress}
          >
            <Wagering className="max-w-[30px]" />
            <span className="text-[12px] font-medium mt-1">Wagering</span>
          </button>
          <button
            className={isGame ? activeTheme : theme}
            onClick={onGamePress}
          >
            <GamingIcon className="max-w-[28px]" />
            <span className="text-[12px] font-medium mt-1">GameHub</span>
          </button>
          <button
            className={isStreaming ? activeTheme : theme}
            onClick={onStreamingPress}
          >
            <SteamingIcon className="max-w-[30px]" />
            <span className="text-[12px] font-medium mt-1">StreamHub</span>
          </button>
          <button
            className={isChat ? activeTheme : theme}
            onClick={onChatPress}
          >
            {!!chatNotificationCount && (
              <div className="bg-red-600 rounded-full w-6 h-6 flex justify-center items-center absolute mb-12 left-[90px]">
                <span className="text-white">{chatNotificationCount > 9 ? "9+" : chatNotificationCount}</span>
              </div>
            )}
            <ChatIcon className="max-w-[30px]" />
            <span className="text-[12px] font-medium mt-1">Chat</span>
          </button>
        </div>
        <div className="flex flex-col gap-[2vh] items-center">
          <button
            className={isOptions ? activeTheme : theme}
            onClick={onOptionsPress}
          >
            <HamburgerIcon className="max-w-[28px]" />
          </button>
          <button
            className={isNotifications ? activeTheme : theme}
            onClick={onNotificationsPress}
          >
            <BellIcon className="max-w-[28px]" />
            {!!notificationCount && (
              <div className="bg-red-600 rounded-full w-6 h-6 flex justify-center items-center fixed bottom-20 left-[90px]">
                <span className="text-white">{notificationCount > 9 ? "9+" : notificationCount}</span>
              </div>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
