import Event from "components/Events";
import Sidebar from "components/SideBar";
import { useEffect, useState } from "react";
import { AppDispatch, RootState } from "../../store";

import ConsolModal from "components/Consoles";
import DailyWinners from "components/DailyWinners";
import InAppStreamViewer from "components/InAppStreamViewer";
import LoadingAnim from "components/Loader";
import Menu from "components/Menu";
import NotificationModal from "components/Notifications";
import { useNavigate } from "react-router";
import { getPrizePoolValue } from "store/auth/actions";
import { resetCurrentMatch } from "store/currentMatch/currentMatchSlice";
import { resetEventsData } from "store/events/eventSlice";
import { getLinkedAccounts } from "store/linkedAccounts/action";
import { resetLiveBrackets } from "store/liveBrackets/liveBracketSlice";
import { getCurrentMatchState } from "store/matchMaking/actions";
import {
  resetBackgroundMatch,
  resetMatchmakingSlice,
  setConsole,
} from "store/matchMaking/matchMakingSlice";
import { getNotifications } from "store/notifications/actions";
import { resetActionData } from "store/notifications/notificationSlice";
import { getStakedMatches } from "store/stake/action";
import { getEndedTournaments } from "store/tournament/action";
import {
  resetTournamentMaking,
  resetTournamentSlice,
} from "store/tournament/tournamentSlice";
import { getUser } from "store/user/action";
import { getBalance } from "store/wallet/action";
import Header from "../../components/header";
import {
  getEvents,
  getLives,
  getStakingEvents,
  getTournaments,
} from "../../store/events/action";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getWinners } from "../../store/winners/action";

export default function HomeScreen() {
  const [visible, setVisible] = useState(false);
  const [consoles, setConsoles] = useState<any[]>([]);
  const [visibleOptionsMenu, setVisibleOptionsMenu] = useState(false);
  const [visibleNotifications, setVisibleNotifications] = useState(false);
  const dispatch: AppDispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useAppSelector((state: RootState) => state.auth);
  const data = useAppSelector((state: RootState) => state.event);

  const [visibleStreaming, setVisibleStreaming] = useState(false);
  const [streamData, setStreamData] = useState<any>();

  const resetStates = () => {
    dispatch(resetTournamentMaking());
    dispatch(resetCurrentMatch());
    dispatch(resetBackgroundMatch());
    dispatch(resetLiveBrackets());
    dispatch(resetEventsData());
    dispatch(resetMatchmakingSlice());
    dispatch(resetTournamentSlice());
    dispatch(resetActionData());
  };

  const fetchData = () => {
    dispatch(getEvents());
    dispatch(getLives("GAMEPLAY"));
    dispatch(getTournaments());
    dispatch(getStakingEvents());
    dispatch(getWinners());
    dispatch(getPrizePoolValue());
    dispatch(getUser());
    dispatch(getLinkedAccounts());
    dispatch(getStakedMatches());
    dispatch(getCurrentMatchState({ callback: () => { } }));
    dispatch(getBalance());
    dispatch(getEndedTournaments({ payload: 1 }));
  };

  useEffect(() => {
    if (!user.userToken) return;
    resetStates();
    fetchData();
  }, [dispatch]);

  return (
    <div className="w-full  bg-background bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex  ">
      <Sidebar
        onOptions={() => setVisibleOptionsMenu(true)}
        onNotifications={() => setVisibleNotifications(true)}
      />
      <div className="flex flex-col justify-start my-[80px] md:my-0 p-[10px] md:p-8 items-center gap-y-8 overflow-x-hidden">
        <Header
          onClick={() => navigate("/login")}
          onPressUser={() => {
            navigate("/profile");
          }}
        />
        <div className="w-full flex flex-col gap-6 ">
          <DailyWinners />
          <Event
            onPress={(data: any) => {
              setConsoles(data);
              setVisible(true);
            }}
            onPressInAppStream={(data) => {
              setStreamData(data);
              setVisibleStreaming(true);
            }}
          />
        </div>
      </div>
      <InAppStreamViewer
        visible={visibleStreaming}
        streamRoomId={streamData?.inApp?.streamRoomId}
        streamToken={streamData?.inApp?.streamToken}
        username={streamData?.username}
        profilePicture={streamData?.profilePicture}
        onClose={() => setVisibleStreaming(false)}
      />
      <ConsolModal
        visible={visible}
        onPress={(item: any) => {
          dispatch(setConsole(item));
          setVisible(false);
          navigate("/duels/duel-overview");
        }}
        data={consoles}
        onClose={() => setVisible(false)}
      />
      {/* <LoadingAnim
        loading={user.loader}
      /> */}
      <Menu
        visible={visibleOptionsMenu}
        onClose={() => setVisibleOptionsMenu(false)}
      />
      <NotificationModal
        visible={visibleNotifications}
        onClose={() => setVisibleNotifications(false)}
      />
    </div>
  );
}
