import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "components/SideBar";
import ButtonDc from "components/Button";
import Header from "components/header";
import Menu from "components/Menu";
import NotificationModal from "components/Notifications";
import matchCoverPng from 'assets/sideBar/matchcover.png';
import stakeCoverPng from 'assets/sideBar/stakecover.png';
import { RootState } from "store/index";
import { useAppSelector } from "store/hooks";
import { toast } from "react-toastify";

export default function WageringOptionsScreen({ }) {
  const navigate = useNavigate();
  const isSuspended = useAppSelector(
    (state: RootState) => state.user
  ).user?.isSuspended;
  const [visibleOptionsMenu, setVisibleOptionsMenu] = useState(false);
  const [visibleNotifications, setVisibleNotifications] = useState(false);

  return (
    <div className="mx-auto w-full  bg-background bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex">
      <Sidebar
        onOptions={() => setVisibleOptionsMenu(true)}
        onNotifications={() => setVisibleNotifications(true)}
      />
      <div className="flex flex-col w-full h-auto xl:h-screen p-8 overflow-x-hidden">
        <Header
          onClick={() => navigate("/login")}
          onPressUser={() => {
            navigate("/profile");
          }}
        />

        <div className=" w-full  md:gap-[2em] flex flex-col items-center mt-10 max-md:gap-3 max-md:my-20">
          <div
            className="flex flex-col backdrop-blur-sm con rounded-[32px] p-10 max-md:p-5 w-full max-md:w-[calc(100%-10px)] items-center justify-center"
          >
            <div className="flex flex-col gap-4">
              <span className="text-3xl text-white font-bold text-center max-md:mt- max-md:text-2xl">
                Wagering Options
              </span>
              <span className=" text-white text-center text-xl font-normal max-md:text-lg">
                <span className="font-bold pr-2">
                  Choose how you want to wager:
                </span>
                Play against others or place your bets on ongoing games.
              </span>
            </div>
            <div className={`flex desktop:flex-row flex-col items-center justify-center desktop:mt-10 gap-10 md:my-8 max-md:gap-2 max-md:my-3`}>
              <button className="flex desktop:flex-col max-md:flex-col rounded-xl p-4 md:p-8 items-center justify-center bg-borderColor gap-2 transform transition-transform hover:scale-105 hover:shadow-lg hover:ring-2 ring-primaryActive"
                onClick={() => {
                  if (isSuspended) return toast.warning('Account Suspended');
                  navigate("/more-events/Staking-Events");
                }}>
                <img src={stakeCoverPng} alt="Stake Cover" className="w-[30vw] desktop:w-[40vh] rounded-xl" />
                <div className="flex flex-col justify-center gap-2 items-center">
                  <span className="text-white font-bold desktop:text-2xl max-md:text-lg mt-2">Stake and Win</span>
                  <span className="text-disabled text-center md:w-[30vw]  desktop:text-xl max-md:text-sm mb-6">
                    Bet on live matches! Pick the winning team or player, and if your prediction is correct, watch your earnings grow.
                  </span>
                  <ButtonDc
                    type="primary"
                    text="Stake Now"
                    action={() => {
                      if (isSuspended) return toast.warning('Account Suspended');
                      navigate("/more-events/Staking-Events");
                    }}
                    short

                  />
                </div>
              </button>
              <button className="flex desktop:flex-col max-md:flex-col rounded-xl p-4 md:p-8 items-center justify-center bg-borderColor gap-2 transform transition-transform hover:scale-105 hover:shadow-lg  hover:ring-2 ring-primaryActive"
                onClick={() => {
                  if (isSuspended) return toast.warning('Account Suspended');
                  navigate("/duels");
                }}>
                <img src={matchCoverPng} alt="Stake Cover" className="w-[30vw] desktop:w-[40vh] rounded-xl" />
                <div className="flex flex-col justify-center gap-2 items-center">
                  <span className="text-white font-bold desktop:text-2xl max-md:text-lg mt-2">Play Match</span>
                  <span className="text-disabled text-center  md:w-[30vw] desktop:text-xl max-md:text-sm mb-6">
                    Showcase your skills by challenging others in head-to-head matches. Bet confidently, win, and claim the prize!
                  </span>
                  <ButtonDc
                    type="primary"
                    text="Play Now"
                    action={() => {
                      if (isSuspended) return toast.warning('Account Suspended');
                      navigate("/duels");
                    }}
                    short

                  />
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <Menu
        visible={visibleOptionsMenu}
        onClose={() => setVisibleOptionsMenu(false)}
      />
      <NotificationModal
        visible={visibleNotifications}
        onClose={() => setVisibleNotifications(false)}
      />
    </div>
  );
}
